h4 {
  font-family: Arial, Helvetica, sans-serif;
  max-width: 90%;
  margin: auto;
  text-align: left;
}

.note-color {
}

.note-color.light {
  color: #424141;
}

.note-color.dark {
  color: grey;
}

.note-topNoteContainer {
  max-width: 90%;
  margin: auto;
}

.note-indexContainer {
  display: inline-block;
  width: 5%;
  vertical-align: top;
  text-align: right;
  padding-top: 3.5px;
}

.note-leftNoteContainer {
  display: inline-block;
  width: 75%;
}

.note-rightNoteContainer {
  vertical-align: top;
  display: inline-block;
  width: 20%;
  text-align: center;
}

.note-leftNoteIcon {
  max-width: 9%;
}

.note-rightNoteIcon {
  max-width: 9%;
}

.note-editIconColor {
  color: #84b59c;
}

.note-editIconColor:hover {
  color: #424141;
  cursor: pointer;
}

.note-deleteIconColor {
  color: lightcoral;
}

.note-deleteIconColor:hover {
  color: red;
  cursor: pointer;
}

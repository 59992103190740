.cap-light-form-input {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  color: grey;
  margin-left: auto;
  margin-right: auto;
}

.cap-span-errors {
  font-size: 12px;
  display: none;
  color: red;
}

/* .cap-input:invalid[focused="true"] {
  border: 2px solid lightcoral;
}
.cap-input:invalid[focused="true"] ~ .span-errors {
  display: block;
} */

.cap-input {
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0px;
}

.cap-input.light {
  border-color: var(--dark-light-grey);
  background-color: var(--light-theme-white);
}

.cap-input.dark {
  border-color: var(--dark-theme-lighter-dark);
  background-color: var(--dark-light-grey);
}

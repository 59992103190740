.carousel-wrapper {
  /* background-color: blue; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  max-width: 80%;
  margin: auto;
  overflow: hidden;
}

.carousel-div {
  background-color: #84b59c;
  display: inline-block;
  vertical-align: top;
  height: 200px;
  width: 50%;
}

.slide-entry {
  animation: carousel-entry 1s ease forwards;
}

.slide-exit {
  animation: carousel-exit 1s ease forwards;
}

@keyframes carousel-entry {
  0% {
    /* opacity: 0; */
    transform: translateX(-200%);
  }
  100% {
    /* opacity: 1; */
    transform: translateX(0%);
  }
}

@keyframes carousel-exit {
  0% {
    /* opacity: 1; */
    transform: translateX(0%);
  }
  100% {
    /* opacity: 0; */
    transform: translateX(200%);
  }
}

.light-form-input {
  margin: 10px;
  display: flex;
  flex-direction: column;
  color: grey;
  /* vertical-align: top;
  display: inline-block; */
}

.dark-form-input {
  margin: 10px;
  display: flex;
  flex-direction: column;
  color: grey;
}

.span-errors {
  font-size: 12px;
  font-weight: 600;
  display: none;
  color: lightcoral;
}

input:invalid[focused="true"] {
  border: 2px solid lightcoral;
}
input:invalid[focused="true"] ~ .span-errors {
  display: block;
}

.input {
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0px;
  border: 2px solid;
  background-color: #fff;
  border: 2px solid grey;
}

.input:focus {
  outline: none !important;
  border: 2px solid #f7a90d;
}

.note-carousel {
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .note-carousel {
    margin: auto;
    max-width: 90%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .note-carousel {
    margin: auto;
    max-width: 90%;
    padding-top: 0px;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .note-carousel {
    margin: auto;
    max-width: 90%;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .note-carousel {
    margin: auto;
    max-width: 70%;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .note-carousel {
    margin: auto;
    max-width: 70%;
  }
}

.btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 5px;
}

.sub-button {
  width: 50%;
}

.login-icon {
  display: flex;
  justify-content: center;
}

.form {
  padding: 20px;
  border-radius: 10px;
}

.form.light {
  background-image: linear-gradient(to bottom, #f7f3e8, #fff);
}

.form.dark {
  background-image: linear-gradient(to bottom, #31333f, #000);
}

.log-reg-btn {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.log-reg-btn:hover {
  transform: scale(1.03);
  text-decoration: underline;
}

.log-reg-btn.light {
  color: var(--light-theme-grey);
}

.log-reg-btn.dark {
  color: var(--dark-theme-purple);
}

.log-pwreset-btn {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-left: 20px;
}

.log-pwreset-btn:hover {
  transform: scale(1.03);
  text-decoration: underline;
}

.log-pwreset-btn.light {
  color: var(--light-theme-grey);
}

.log-pwreset-btn.dark {
  color: var(--dark-theme-purple);
}

.slick-prev:before,
.slick-next:before {
  color: darkgrey;
}

.tmf-form {
  float: none;
  overflow: hidden;
  padding: 20px;
  border-radius: 10px;
}

.tmf-form.dark {
  background-color: #31333f;
}

.tmf-form.light {
  background-color: #f7f3e8;
}

.leftContainer.dark {
  background-color: #31333f;
  border-radius: 10px;
}

.leftContainer.light {
  background-color: #f7f3e8;
  border-radius: 10px;
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .leftContainer {
    /* display: inline-block; */
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }

  .rightContainer {
    /* vertical-align: top; */
    /* display: inline-block; */
    width: 90%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 50px;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .leftContainer {
    width: 90%;
    margin-top: 20px;
    margin: auto;
    margin-bottom: 50px;
  }

  .rightContainer {
    width: 90%;
    margin-top: 20px;
    margin: auto;
    margin-bottom: 20px;
    padding-bottom: 50px;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .leftContainer {
    width: 90%;
    margin-top: 20px;
    margin: auto;
    margin-bottom: 50px;
  }

  .rightContainer {
    width: 90%;
    margin-top: 20px;
    margin: auto;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .leftContainer {
    display: inline-block;
    width: 54%;
    margin-top: 20px;
  }

  .rightContainer {
    vertical-align: top;
    display: inline-block;
    width: 38%;
    margin-top: 20px;
    margin-left: 8%;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .leftContainer {
    display: inline-block;
    width: 54%;
    margin-top: 20px;
  }

  .rightContainer {
    vertical-align: top;
    display: inline-block;
    width: 38%;
    margin-top: 20px;
    margin-left: 8%;
  }
}

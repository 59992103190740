h2 {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
}
/* 
.line {
  max-width: 50%;
  margin: auto;
  padding: 1px 0px 0px 0px;
  margin-top: 20px;
} */

.spacer {
  margin-top: 2rem;
}

.color {
  font-weight: bold;
}

.color.light {
  color: #424141;
}

.color.dark {
  color: #f7a90d;
}

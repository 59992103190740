.cap-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 5px;
}

.cap-button-59 {
  width: 50%;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.cap-button-59.light {
  background-color: #f7a90d;
  border: 2px solid #f7a90d;
  color: #fff;
  fill: #f7a90d;
}
.cap-button-59.light:focus {
  color: #f63466;
}

.cap-button-59.light:hover {
  border-color: #f63466;
  background-color: #f63466;
  color: #fff;
  fill: #fff;
  transform: scale(1.05);
}

.cap-button-59.dark {
  /* background-color: var(--dark-theme-lighter-dark);
  border: 2px solid var(--dark-theme-lighter-dark);
  color: #fff;
  fill: var(--dark-theme-lighter-dark); */
  border-color: #f7a90d;
  background-color: #f7a90d;
  border: 2px solid #f7a90d;
  color: var(--dark-theme-lighter-dark);
  fill: var(--dark-theme-lighter-dark);
}

/* .cap-button-59.dark:focus {
  color: #f63466;
} */

.cap-button-59.dark:hover {
  border-color: grey;
  background-color: grey;
  color: #fff;
  fill: #fff;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .cap-button-59 {
    min-width: 170px;
  }
}

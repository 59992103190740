.container {
  display: flex;
}

.column {
  flex-direction: column;
}

.dot {
  height: 100px;
  width: 100px;
  border: 5px solid #f7a90d;
  margin-top: 10%;
  margin-left: 25%;
}

/* .wiggle {
    background: #f7e29d;
    background-color: #f7e29d;
    color: #f7e29d;
    border: #f7e29d;

  } */

/* @keyframes shake {
    0% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(5deg);
    }
  } */

@keyframes identifier {
  0% {
    margin-left: -10%;
  }
  100% {
    margin-left: 110%;
  }
}

.form-input {
  margin: 10px;
  display: flex;
  flex-direction: column;
  color: grey;
}

/* .span-errors {
  font-size: 12px;
  display: none;
  color: red;
} */

/* input:invalid[focused="true"] {
  border: 2px solid red;
} */
input:invalid[focused="true"] ~ .span-errors {
  display: block;
}

.input {
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0px;
  border-color: darkgray;
  max-height: 250px;
  resize: vertical;
  /* background-image: linear-gradient(to bottom, #f7f3e8, #fff); */
}

.cap-header {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  text-align: center;
  font-size: 3.5rem;
}

.cap-line {
  max-width: 50%;
  margin: auto;
  padding: 1px 0px 0px 0px;
  margin-top: 20px;
}

.cap-spacer {
  margin-top: 1.5rem;
}

.cap-color {
  color: #424141;
  font-weight: bold;
}

.navbar {
  height: 80px;
  display: flex;
  /* justify-content: start; */
  align-items: center;
  justify-content: space-between;
  border-top: 3px solid #84b59c;
  border-left-width: 0;
  border-right-width: 0;
  justify-content: right;
}

.navbar.light {
  border-image: linear-gradient(to right, #fffd80, #f63466) 1 stretch;
  background-color: transparent;
}

.navbar.dark {
  border-image: linear-gradient(to right, #fffd80, #f7a90d) 1 stretch;
  background-color: var(--dark-theme-darkest);
}

.sidebar-icon {
  border: 2px solid transparent;
}

.sidebar-icon:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: all ease 500ms;
}

.sidebar-icon.dark:hover {
  border: 2px solid #fff;
}

.sidebar-icon.light:hover {
  border: 2px solid #f63466;
}

.sidebar-icon.light:active {
  background-color: #f63466;
}

.sidebar-icon.dark:active {
  background-color: #f7a90d;
}

.open-sidebar-icon {
  margin-left: 2rem;
  margin-right: 2.5rem;
  font-size: 2rem;
  color: #f0f2f6;
  transform: scale(1);
  transition: all ease 500ms;
  padding: 0.4rem;
}

.logout-icon {
  margin-left: 2.5rem;
  margin-right: 2rem;
  font-size: 1.8rem;
  color: #f7a90d;
  padding-top: 0.11rem;
  padding-left: 0.2rem;
  padding-right: 0.16rem;
}

.close-sidebar-icon {
  font-size: 1.8rem;
  background: none;
  transform: scale(1);
  transition: all ease 500ms;
  padding-top: 0.11rem;
  padding-left: 0.2rem;
  padding-right: 0.16rem;
  border: 2px solid transparent;
}

.logout {
  margin-right: 2rem;
  font-size: 2rem;
}

.nav-menu {
  z-index: 999;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 350ms;
}

.nav-menu.dark {
  background-color: var(--dark-theme-darkest);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 1);
}

.nav-menu.light {
  background-color: #f0f2f6;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.nav-menu.active {
  left: 0;
  transition: 250ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px, 0px, 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0p 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  color: #f7f3e8;
  background-color: #b64f48;
}

.nav-menu-items {
  width: 100%;
  padding-left: 0;
}

.nav-menu-items.light {
  background-color: #f0f2f6;
}

.nav-menu-items.dark {
  background-color: #000;
}

.navbar-toggle {
  color: #424141;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 2rem;
}

.side-span {
  margin-left: 16px;
}

.navIcon svg {
  color: #84b59c !important;
}

.menu-bar-text {
  margin-left: 2rem;
  margin-right: 1.5rem;
  color: #424141;
}

.ordered-list {
  padding-left: 1rem;
  font-size: 23px;
}

.icon {
  font-size: 1.2rem;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
  float: right;
}

.icon.open {
  transform: rotate(90deg);
}

.icon:hover {
  color: #f63466;
}

@media (max-width: 767px) {
  .tale-header {
    font-size: 1.5rem;
  }
  .navbar {
    height: 40px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    justify-content: right;
    align-items: center;
  }

  .logout-icon {
    margin-left: 1.5rem;
    margin-right: 1rem;
    font-size: 1.5rem;
    padding-top: 1rem;
  }

  .sidebar-icon.dark:hover {
    border: 2px solid transparent;
  }

  .sidebar-icon.light:hover {
    border: 2px solid transparent;
  }

  .navbar-toggle {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 2rem;
  }

  .nav-menu {
    z-index: 999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 350ms;
  }
}

.login-wrapper {
}

.login-left-container {
  width: 60%;
  display: inline-block;
  vertical-align: top;
  border: 3px solid #84b59c;
  border-left-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  height: 100vh;
  white-space: nowrap;
}

.login-left-container.light {
  background-color: var(--light-theme-orange);
  background-image: linear-gradient(360deg, #f7a90d, #fffd80);
  border-image: linear-gradient(to bottom, #fffd80, #f63466) 1 stretch;
  transition: 500ms;
}

.login-left-container.dark {
  background-color: var(--dark-theme-darkest);
  background-image: linear-gradient(
    360deg,
    var(--dark-theme-darkest),
    var(--dark-theme-lighter-dark)
  );
  border-image: linear-gradient(
      to bottom,
      var(--dark-theme-lighter-dark),
      #f7a90d
    )
    1 stretch;
  transition: 500ms;
}

.login-right-container {
  width: 40%;
  height: 100vh;
  display: inline-block;
  vertical-align: top;
}

.login-right-container.light {
  background-color: #fff;
  transition: 500ms;
}

.login-right-container.dark {
  background-color: #000;
  transition: 500ms;
}

.login-settings {
  float: right;
  padding-top: 20px;
}

.settings-icon {
  border: 2px solid transparent;
  font-size: 2rem;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
  float: right;
}

.settings-icon.open {
  transform: rotate(90deg);
}

.settings-icon:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: all ease 500ms;
}

.settings-icon.light:hover {
  border: 2px solid #f63466;
}

.settings-icon.dark:hover {
  border: 2px solid #fff;
}

.settings-icon:active {
  background-color: #f63466;
}

.open-settings-icon {
  margin-left: 2rem;
  margin-right: 2.5rem;
}

.settings-bar {
  position: absolute;
  padding: 10px;
  margin-left: -3rem;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 600;
}

.settings-bar.light {
  background-color: #f7a90d;
  color: #fff;
  background-color: #f7a90d;
}

.settings-bar.dark {
  background-color: #000;
  color: #f7a90d;
}

.show {
  transition: 0.5s;
  margin-top: 3rem;
}

.hide {
  transition: 0.2s;
  margin-top: -10rem;
}

.speed-slider {
  accent-color: #fffd80;
  transition: ease-in-out 500ms;
}

.mute-graph-btn {
  margin-top: 10px;
  padding: 10px;
  transition: ease-in-out 200ms;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 550;
}

.mute-graph-btn:hover {
  transform: scale(1.05);
}

.mute-graph-btn.light {
  color: #fff;
  background-color: #f63466;
  border: 2px solid #f63466;
}
.mute-graph-btn.light:hover {
  background-color: #f63466;
  border: 2px solid #f63466;
}

.mute-graph-btn.dark {
  color: #fff;
  background-color: var(--dark-theme-lighter-dark);
  border: 2px solid var(--dark-theme-lighter-dark);
}

.mute-graph-btn.dark:hover {
  color: #000;
  background-color: #f7a90d;
  border: 2px solid #f7a90d;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .login {
    margin: auto;
    max-width: 95%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .login-left-container {
    width: 0%;
  }

  .login-right-container {
    width: 99%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .login {
    margin: auto;
    max-width: 95%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .login-left-container {
    width: 0%;
  }

  .login-right-container {
    width: 99%;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .login {
    margin: auto;
    max-width: 95%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .login {
    margin: auto;
    max-width: 95%;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .login-left-container {
    width: 70%;
  }

  .login-right-container {
    width: 30%;
  }
}

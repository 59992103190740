.cap-first-half {
  margin-top: 20px;
  margin-left: 26px;
  vertical-align: top;
  display: inline-block;
  position: relative;
}

.cap-first-half-ani {
  margin-top: 6px;
  /* margin-left: 10px; */
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.cap-second-half {
  margin-top: 9px;
  margin-left: 15px;
  vertical-align: top;
  display: inline-block;
  font-weight: 500;
  font-size: 2rem;
}

.cap-second-half.light {
  color: var(--light-theme-grey);
}
.cap-second-half.dark {
  color: var(--dark-light-grey);
}

.cap-robo-wrap {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
}

.checkmark.light {
  border: solid var(--light-theme-grey);
}
.checkmark.dark {
  border: solid var(--dark-light-grey);
}

/* On mouse-over, add a grey background color */
.container.light:hover input ~ .checkmark {
  background-color: var(--dark-light-grey);
}
.container.dark:hover input ~ .checkmark {
  background-color: var(--dark-theme-lighter-dark);
}

/* When the checkbox is checked, add a blue background */
.container.light input:checked ~ .checkmark {
  background-color: var(--dark-light-grey);
}
.container.dark input:checked ~ .checkmark {
  background-color: var(--light-theme-grey);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.container .checkmark.light:after {
  border: solid var(--dark-theme-darkest);
  left: 6px;
  top: 1px;
  width: 7px;
  height: 15px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container .checkmark.dark:after {
  border: solid var(--dark-light-grey);
  left: 6px;
  top: 1px;
  width: 7px;
  height: 15px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-line {
  max-width: 100%;
  margin: auto;
  padding: 1px 0px 0px 0px;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .cap-first-half {
    margin-top: 16px;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .cap-first-half {
    margin-top: 16px;
  }
}

.notification {
  margin: auto;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 20px;
}

.close-icon {
  margin: 2px;
  cursor: pointer;
  float: right;
}
.close-icon:hover {
  color: lightcoral;
}

.notification-icon {
  padding-top: 5px;
  max-width: 30%;
  float: left;
}

.notification-header {
  padding-top: 7px;
  padding-left: 5px;
  font-weight: bold;
}
.error {
  background-color: #fdeded;
  color: #5f2120;
}

.warning {
  background-color: #fff4e5;
  color: #663c00;
}

.info {
  background-color: #e5f6fd;
  color: #014361;
}

.success {
  background-color: #edf7ed;
  color: #1e4620;
}

.toast-para {
  text-align: left;
  margin: auto;
  max-width: 85%;
  padding-left: 5px;
}

.toast-span {
  float: left;
}

.cap-form {
  padding: 20px;
  border-radius: 10px;
}

.cap-form.light {
  background-image: linear-gradient(
    to bottom,
    var(--light-theme-offwhite),
    var(--light-theme-white)
  );
}

.cap-form.dark {
  background-image: linear-gradient(
    to bottom,
    var(--dark-theme-lighter-dark),
    var(--dark-theme-black)
  );
}

.cap-form-progress {
  padding: 20px;
  border-radius: 10px;
}

.cap-form-progress.light {
  background-image: linear-gradient(
    to bottom,
    var(--light-theme-offwhite),
    var(--light-theme-white)
  );
}

.cap-form-progress.dark {
  background-image: linear-gradient(
    to bottom,
    var(--dark-theme-lighter-dark),
    var(--dark-theme-black)
  );
}

.cap-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: 10px;
}

.cap-q-span {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}

.cap-q-span.light {
  color: var(--light-theme-grey);
}

.cap-q-span.dark {
  color: var(--dark-theme-grey);
}

.cap-success {
  color: #73af55;
}
.cap-error {
  color: #d06079;
}

.link.light {
  color: var(--light-theme-grey);
}

.link.dark {
  color: var(--dark-theme-grey);
}
